/* eslint-disable jsx-a11y/tabindex-no-positive */
import { ReactElement, useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';

import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import SelectEmailDomain from '~/ui/components/inputs/SelectEmailDomain';
import InputMask from '~/ui/components/inputs/InputMask';
import AvatarUpload from '~/ui/components/inputs/AvatarUpload';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Button from '~/ui/components/common/Button';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import Loader from '~/ui/components/common/Loader';

import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import memberOptions, { MemberType } from '~/ui/constants/memberTypes';
import { IOption } from '~/types';

import validate from './validate';
import styles from './Form.module.scss';

interface IProps {
  isProcessing: boolean;
  leavePageDescription: string;
  cancelLinkTo: any;
  emailDomainOptions: IOption[];
  onSubmit: (vals: any) => void;
}

interface IOptions {
  states: IOption[];
  credentials: IOption[];
  educations: IOption[];
  employmentStatuses: IOption[];
  subRoles: IOption[];
}

const Form = ({
  isProcessing,
  leavePageDescription,
  cancelLinkTo,
  emailDomainOptions,
  onSubmit,
}: IProps): ReactElement => {
  const [options, setOptions] = useState<IOptions>(null);
  const [loading, setLoading] = useState(false);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const onGetActTeamsAvailable = useStoreActions(actions => actions.actTeam.onGetActTeamsAvailable);

  const clinicId = useStoreState(state => state.clinic.current?.id);
  const availableActTeams = useStoreState(state => state.actTeam.availableActTeams);

  const teamOptions = useMemo(
    () => formatDictionaryOptions(availableActTeams),
    [availableActTeams],
  );

  const {
    handleSubmit,
    register,
    control,
    setValue,
    unregister,
    watch,
    formState: { errors, isDirty },
  } = useForm({ mode: 'onBlur', resolver: validate });

  const { primaryRole } = watch();
  const globalUserSelected = primaryRole === MemberType.GLOBAL_USER;

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const [states, credentials, educations, employmentStatuses, subRoles] = await Promise.all([
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.State)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Credentials)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Education)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.EmploymentStatus)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.SubRole)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setOptions({ states, credentials, educations, employmentStatuses, subRoles });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  // fetch available team options if global user role selected
  const fetchAvailableTeams = useCallback(async () => {
    if (globalUserSelected) {
      await onGetActTeamsAvailable({ clinicId: String(clinicId) });
    }
  }, [globalUserSelected, clinicId, onGetActTeamsAvailable]);

  useEffect(() => {
    fetchAvailableTeams();
  }, [fetchAvailableTeams]);

  useEffect(() => {
    if (emailDomainOptions.length) {
      setValue('emailDomain', emailDomainOptions[0].value);
    }
  }, [emailDomainOptions, setValue]);

  // select all items in select component
  const setAll = useCallback(
    (all?: boolean) => {
      if (all) {
        const filteredTeamIds = availableActTeams.map(m => m.id);
        setValue('teamIds', filteredTeamIds);
      } else {
        setValue('teamIds', []);
      }
    },
    [availableActTeams, setValue],
  );

  // set all teams selected by default
  useEffect(() => {
    if (globalUserSelected) {
      setAll(true);
    } else {
      unregister(['teamIds']);
    }
  }, [globalUserSelected, setAll, unregister]);

  if (loading || !options) return <Loader />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        <Paper>
          <Box sx={{ p: 2 }}>
            <p className={styles.formTitle}>Personal Details</p>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Input
                  tabIndex={1}
                  register={register}
                  errors={errors}
                  name="firstName"
                  label="First Name"
                />
              </Grid>
              <Grid item sm={6}>
                <Grid container spacing={2}>
                  <Grid item sm={globalUserSelected ? 6 : 12}>
                    <Select
                      tabIndex={5}
                      options={memberOptions}
                      name="primaryRole"
                      control={control}
                      errors={errors}
                      label="Primary Role"
                      hideSelectedOptions={false}
                      openMenuOnFocus
                    />
                  </Grid>
                  {globalUserSelected && (
                    <Grid item sm={6}>
                      <Select
                        isMulti
                        oneLineMulti
                        closeMenuOnSelect={false}
                        showCustomComponents
                        showSelectAll
                        hideSelectedOptions={false}
                        name="teamIds"
                        options={teamOptions}
                        control={control}
                        errors={errors}
                        label="Assigned Teams"
                        isClearable
                        placeholder="All"
                        setAll={setAll}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={2}
                  register={register}
                  errors={errors}
                  name="lastName"
                  label="Last Name"
                />
              </Grid>
              <Grid item sm={6}>
                <Select
                  tabIndex={6}
                  isMulti
                  control={control}
                  errors={errors}
                  name="subRoleIds"
                  label="Sub-Role"
                  options={options.subRoles}
                  hideSelectedOptions={false}
                  openMenuOnFocus
                />
              </Grid>
              <Grid item sm={6}>
                <InputMask
                  tabIndex={3}
                  control={control}
                  errors={errors}
                  name="phone"
                  label="Phone"
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={7}
                  register={register}
                  errors={errors}
                  name="address.city"
                  label="City"
                />
              </Grid>
              <Grid item sm={6}>
                <div className={styles.combinedInputWrapper}>
                  <Input
                    combinedInput
                    tabIndex={4}
                    register={register}
                    errors={errors}
                    name="email"
                    label="Email"
                  />
                  <SelectEmailDomain
                    name="emailDomain"
                    control={control}
                    errors={errors}
                    options={emailDomainOptions}
                  />
                </div>
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={8}
                  register={register}
                  errors={errors}
                  name="address.line1"
                  label="Address 1"
                />
              </Grid>
              <Grid item sm={6}>
                <AvatarUpload control={control} name="photo" errors={errors} isPhoto />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={9}
                  register={register}
                  errors={errors}
                  name="address.line2"
                  label="Address 2"
                />
              </Grid>
              <Grid item sm={6} className={styles.col} />
              <Grid item sm={3} className={styles.col}>
                <Select
                  tabIndex={10}
                  options={options.states}
                  name="address.stateId"
                  control={control}
                  errors={errors}
                  label="State"
                  hideSelectedOptions={false}
                  openMenuOnFocus
                />
              </Grid>
              <Grid item sm={3} className={styles.col}>
                <InputMask
                  tabIndex={11}
                  alwaysShowMask={false}
                  mask="99999"
                  name="address.zipCode"
                  control={control}
                  errors={errors}
                  label="Zip Code"
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <div className={styles.paperContainer}>
          <Paper>
            <Box sx={{ p: 2 }}>
              <p className={styles.formTitle}>Work Details</p>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Select
                    tabIndex={12}
                    options={options.credentials}
                    control={control}
                    errors={errors}
                    name="workDetails.credentialsId"
                    label="Credentials"
                    hideSelectedOptions={false}
                    openMenuOnFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={15}
                    options={options.employmentStatuses}
                    control={control}
                    errors={errors}
                    name="workDetails.employmentStatusId"
                    label="Employment Status"
                    hideSelectedOptions={false}
                    openMenuOnFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={13}
                    options={options.educations}
                    control={control}
                    errors={errors}
                    name="workDetails.educationId"
                    label="Education"
                    hideSelectedOptions={false}
                    openMenuOnFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <Input
                    tabIndex={16}
                    type="number"
                    register={register}
                    errors={errors}
                    name="workDetails.experience"
                    label="Years of Experience"
                  />
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    tabIndex={14}
                    control={control}
                    openTo="date"
                    errors={errors}
                    label="Hire Date"
                    name="workDetails.hireDate"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <div className={styles.buttonsWrapper}>
            <div className={styles.buttonWrapper}>
              <Link to={cancelLinkTo} className={styles.link}>
                <Button variant="outlined" color="primary" disabled={isProcessing}>
                  Cancel
                </Button>
              </Link>
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isProcessing}
              tabIndex={17}
            >
              Send Invite
            </Button>
          </div>
        </div>
      </form>
      <NavigationConfirmModal when={isDirty && !isProcessing} description={leavePageDescription} />
    </div>
  );
};

export default Form;
