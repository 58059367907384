import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import unionBy from 'lodash/unionBy';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';

import Loader from '~/ui/components/common/Loader';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';

import { useStoreActions } from '~/store/hooks';
import validate from './form/validate';
import compareRoles from '~/utils/compareRoles';
import { extractErrorMessage } from '~/utils/error/error';
import removeActPrefix from '~/utils/text/removeActPrefix';

import { SIGN_IN } from '~/ui/constants/paths';
import { IFormValues } from './form/types';
import { IUserRole } from '~/types';
import { IRole } from '~/store/user/types';
import companyLogo from '~/ui/assets/images/logo.svg';
import styles from './Role.module.scss';

type ILocationState = {
  state: { roles: IRole[]; email: string; password: string };
};

const Role = (): ReactElement => {
  const navigate = useNavigate();
  const location: ILocationState = useLocation();
  const { roles, email, password } = location.state;

  const {
    handleSubmit,
    control,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: validate });

  const { roleId, teamId, clinicId } = watch();

  const clinicOptions = useMemo(
    () =>
      unionBy(
        roles.map(role => ({ label: role.clinic.name, value: role.clinic.id })),
        'value',
      ),
    [roles],
  );

  const roleOptions = useMemo(
    () =>
      unionBy(
        roles.reduce((acc, obj) => {
          if (obj.clinic.id === clinicId) {
            acc.push({ value: obj.id, label: removeActPrefix(obj.name) });
          }

          return acc;
        }, []),
        'value',
      ),
    [clinicId, roles],
  );

  const showClinicSelect = clinicOptions.length > 1;
  const showRoleSelect = !!clinicId || !showClinicSelect;

  const selectedRole = useMemo(
    () => roles.find(role => role.id === roleId && (clinicId ? clinicId === role.clinic.id : true)),
    [roles, roleId, clinicId],
  );

  const teamOptions = useMemo(
    () => selectedRole?.teams.map(team => ({ value: team.id, label: team.name })),
    [selectedRole],
  );

  const showTeamSelect =
    compareRoles(selectedRole?.name, IUserRole.ActTeamMember) ||
    compareRoles(selectedRole?.name, IUserRole.ActTeamLeader);

  const isMultipleTeamRole =
    compareRoles(selectedRole?.name, IUserRole.ProgramAssistant) ||
    compareRoles(selectedRole?.name, IUserRole.GlobalUser);

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  const onLogin = useStoreActions(actions => actions.user.onLogin);

  const onSubmit = async (values: IFormValues) => {
    setIsLoading(true);

    if (isMultipleTeamRole) {
      values.teamId = selectedRole?.teams[0]?.id;
    }

    try {
      await onLogin({
        ...values,
        email,
        password,
      });
    } catch (e) {
      setServerError(extractErrorMessage(e));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!roles) {
      navigate(SIGN_IN);
    }
  }, [navigate, roles]);

  useEffect(() => {
    if (clinicId) {
      setValue('roleId', null);
    }
  }, [clinicId, setValue]);

  useEffect(() => {
    if (!showTeamSelect) {
      unregister('teamId');
    }
  }, [showTeamSelect, unregister]);

  useEffect(() => {
    if (clinicOptions?.length === 1) {
      setValue('clinicId', clinicOptions[0].value);
    }
  }, [clinicOptions, setValue]);

  useEffect(() => {
    if (roleOptions?.length === 1) {
      setValue('roleId', roleOptions[0].value);
    }
  }, [roleOptions, setValue]);

  useEffect(() => {
    if (teamOptions?.length === 1) {
      setValue('teamId', teamOptions[0].value);
    }
  }, [teamOptions, setValue]);

  const getHeaderTitle = () => {
    let title = 'Select Role';

    if (showTeamSelect) {
      title = 'Select Team';
    }

    if (showRoleSelect && !roleId) {
      title = 'Select Role';
    }

    if (showClinicSelect && !clinicId) {
      title = 'Select Clinic';
    }

    return title;
  };

  return (
    <>
      <img src={companyLogo} className={styles.logo} alt="Logo" />
      <Paper className={styles.signIn}>
        <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
          <div className={styles.title}>{getHeaderTitle()}</div>
          {showClinicSelect && (
            <div className={styles.row}>
              <Select
                name="clinicId"
                options={clinicOptions}
                control={control}
                errors={errors}
                label="Select Clinic"
                hideSelectedOptions={false}
              />
            </div>
          )}
          {showRoleSelect && (
            <div className={styles.row}>
              <Select
                name="roleId"
                options={roleOptions}
                control={control}
                errors={errors}
                label="Select Role"
                hideSelectedOptions={false}
              />
            </div>
          )}
          {showTeamSelect && (
            <div className={styles.row}>
              <Select
                name="teamId"
                options={teamOptions}
                control={control}
                errors={errors}
                label="Select Team"
                hideSelectedOptions={false}
              />
            </div>
          )}
          <div className={styles.row}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              className={styles.btn}
              disabled={isLoading || (showTeamSelect && !teamId)}
            >
              Next
            </Button>
          </div>
          {serverError && <div className={styles.serverError}>{serverError}</div>}
          {isLoading && <Loader />}
        </form>
      </Paper>
    </>
  );
};

export default Role;
