export enum MemberType {
  TEAM_MEMBER = 'teamMember',
  TEAM_LEADER = 'teamLeader',
  GLOBAL_USER = 'globalUser',
}

export const memberTypeLabels = {
  [MemberType.TEAM_MEMBER]: 'Team Member',
  [MemberType.TEAM_LEADER]: 'Team Leader',
  [MemberType.GLOBAL_USER]: 'Global User',
};

export default [
  { value: MemberType.TEAM_MEMBER, label: memberTypeLabels[MemberType.TEAM_MEMBER] },
  { value: MemberType.TEAM_LEADER, label: memberTypeLabels[MemberType.TEAM_LEADER] },
  { value: MemberType.GLOBAL_USER, label: memberTypeLabels[MemberType.GLOBAL_USER] },
];
