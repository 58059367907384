import { privateAxios } from '../requestsCollection';
import { IResponse, IParams } from '../types';
import { IActTeamMember, IActTeamMemberAvailableList, IAssignableActTeamMember } from './types';
import { ICreateUserRequest } from '../users/types';

export const getActTeamMembers = (
  clinicId: string,
  actTeamId: string,
  params: IParams,
): IResponse<{ items: IActTeamMember[]; total: number }> =>
  privateAxios(
    {
      method: 'get',
      url: `/clinic/${clinicId}/act-team/${actTeamId}/team-member/list`,
      params,
    },
    'v3/',
  );

export const getAssignableUsers = (
  clinicId: string,
  toTeamId: string | null,
  fromTeamId: string | null,
  role: string,
  params: IParams,
): IResponse<{ items: IAssignableActTeamMember[]; total: number }> =>
  privateAxios(
    {
      method: 'get',
      url: `/clinic/${clinicId}/user-assignment/user-list?${
        toTeamId ? `toTeamId=${toTeamId}&` : ''
      }${fromTeamId ? `fromTeamId=${fromTeamId}&` : ''}role=${role}`,
      params,
    },
    'v2/',
  );

export const assignTeamMember = (
  clinicId: number,
  userId: number,
  teamId: number,
  role: string,
): IResponse<void> =>
  privateAxios(
    {
      method: 'post',
      url: `/clinic/${clinicId}/user-assignment/assign/team-member`,
      data: {
        role,
        userId,
        teamId,
      },
    },
    'v2/',
  );

export const unAssignTeamMember = (
  clinicId: number,
  userId: number,
  teamId: number,
): IResponse<void> =>
  privateAxios(
    {
      method: 'post',
      url: `/clinic/${clinicId}/user-assignment/un-assign/team-member`,
      data: {
        userId,
        teamId,
      },
    },
    'v2/',
  );

export const archiveTeamMember = (
  clinicId: number,
  actTeamId: number,
  teamMemberId: number,
): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}`,
  });

// get all team members from one team
export const getActTeamMemberList = (
  clinicId: string,
  teamId: string,
): IResponse<IActTeamMemberAvailableList[]> =>
  privateAxios(
    {
      method: 'get',
      url: `clinic/${clinicId}/act-team/${teamId}/team-member/available-list`,
    },
    'v3/',
  );

// get all team members from provided teams
export const getTeamMemberList = (
  clinicId: string,
  teamIds?: number[],
): IResponse<IActTeamMemberAvailableList[]> =>
  privateAxios(
    {
      method: 'get',
      url: `clinic/${clinicId}/team-member/available-list?${teamIds
        .map(item => `&teamIds=${item}`)
        .join('')}`,
    },
    'v3/',
  );

export const getActTeamMember = (
  clinicId: string,
  actTeamId: string,
  teamMemberId: string,
): IResponse<IActTeamMember> =>
  privateAxios(
    {
      method: 'get',
      url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}`,
    },
    'v3/',
  );

export const updateTeamMember = (
  clinicId: string,
  actTeamId: string,
  teamMemberId: string,
  data: ICreateUserRequest,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}`,
    data,
  });

export const createTeamMember = (
  clinicId: number,
  actTeamId: number,
  data: ICreateUserRequest,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member`,
    data,
  });

export const createTeamLeader = (
  clinicId: number,
  actTeamId: number,
  data: ICreateUserRequest,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-lead`,
    data,
  });

export const unlockTeamMember = (
  clinicId: number,
  actTeamId: number,
  teamMemberId: number,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}/unlock-account`,
  });

export const resendInvitation = (
  clinicId: number,
  actTeamId: number,
  teamMemberId: number,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}/resend-invitation`,
  });

export const activateTeamMember = (
  clinicId: number,
  actTeamId: number,
  teamMemberId: number,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}/activate`,
  });

export const deactivateTeamMember = (
  clinicId: number,
  actTeamId: number,
  teamMemberId: number,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}/deactivate`,
  });

// global user

export const createGlobalUser = (
  clinicId: number,
  data: ICreateUserRequest & { teamIds?: number[] },
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/global-user`,
    data,
  });

export const archiveGlobalUser = (clinicId: number, userId: number): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/global-user/${userId}`,
  });

export const updateGlobalUser = (
  clinicId: number,
  userId: number,
  data: ICreateUserRequest,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/global-user/${userId}`,
    data,
  });

export const activateGlobalUser = (clinicId: number, userId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/global-user/${userId}/activate`,
  });

export const deactivateGlobalUser = (clinicId: number, userId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/global-user/${userId}/deactivate`,
  });

export const resendInvitationGlobalUser = (clinicId: number, userId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/global-user/${userId}/resend-invitation`,
  });

export const unlockGlobalUser = (clinicId: number, userId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/global-user/${userId}/unlock-account`,
  });

export default {};
