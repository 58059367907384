import SendBird, { FileMessage, GroupChannel, SendBirdError, UserMessage } from 'sendbird';

import getEnv from '~/utils/getEnv';
import { privateAxios } from '../requestsCollection';
import isJson from '~/ui/pages/Coordinate/helpers/isJson';

import ConnectionStatus from '~/ui/pages/Coordinate/models/ConnectionStatus';
import MessageTypes from '~/ui/pages/Coordinate/models/MessageTypes';

import { ITeam } from '~/store/user/types';
import { IResponse } from '../types';

export const removeMember = (channelUrl: string, userIds: string[]): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: '/sendbird/remove-from-channel',
    data: { channelUrl, userIds },
  });

export const sbGetGroupChannel = (channelUrl: string): Promise<GroupChannel> =>
  new Promise<GroupChannel>((resolve, reject) => {
    const sb = SendBird.getInstance();
    if (sb)
      sb.GroupChannel.getChannel(channelUrl, (channel: GroupChannel, error: SendBirdError) => {
        if (error) {
          reject(error);
        } else {
          resolve(channel);
        }
      });
  });

export const sbAddMembersGroupChannel = (
  members: string[],
  channelUrl: string,
): Promise<GroupChannel> =>
  new Promise<GroupChannel>((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel: SendBird.GroupChannel) => {
        channel.inviteWithUserIds(members, (res: GroupChannel, error: SendBirdError) => {
          if (error) {
            reject(error);
          } else {
            resolve(res);
          }
        });
      })
      .catch((error: SendBird.SendBirdError) => reject(error));
  });

export const sbLeaveGroupChannel = (channelUrl: string, id: string): Promise<GroupChannel> =>
  new Promise<GroupChannel>((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel: SendBird.GroupChannel) => {
        const sb = SendBird.getInstance();
        const isDataJson = isJson(channel.data);
        const data = isDataJson ? JSON.parse(channel.data) : null;
        const archivedBy = (data?.archivedBy || [])?.filter((m: string) => m !== id);
        const params = new sb.GroupChannelParams();
        params.data = data ? JSON.stringify({ ...data, archivedBy }) : '{}';
        channel.updateChannel(params, (response, error: SendBirdError) => {
          if (error) {
            reject(error);
          } else {
            channel.leave((_response, e: SendBirdError) => {
              if (e) {
                reject(e);
              } else {
                resolve(response);
              }
            });
          }
        });
      })
      .catch((error: SendBird.SendBirdError) => reject(error));
  });

export const sbArchiveGroupChannel = (channelUrl: string, id: string): Promise<GroupChannel> =>
  new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel: SendBird.GroupChannel) => {
        const sb = SendBird.getInstance();
        const isDataJson = isJson(channel.data);
        const data = isDataJson ? JSON.parse(channel.data) : null;
        const archivedBy = data?.archivedBy || [];
        const params = new sb.GroupChannelParams();
        params.data = data ? JSON.stringify({ ...data, archivedBy: [...archivedBy, id] }) : '{}';

        channel.updateChannel(params, (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      })
      .catch((error: SendBird.SendBirdError) => reject(error));
  });

export const sbMuteGroupChannel = (channelUrl: string, id: string): Promise<GroupChannel> =>
  new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel: SendBird.GroupChannel) => {
        const sb = SendBird.getInstance();
        const isDataJson = isJson(channel.data);
        const data = isDataJson ? JSON.parse(channel.data) : null;
        const mutedBy = data?.mutedBy || [];
        const params = new sb.GroupChannelParams();
        params.data = data ? JSON.stringify({ ...data, mutedBy: [...mutedBy, id] }) : '{}';

        channel.updateChannel(params, (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      })
      .catch((error: SendBird.SendBirdError) => reject(error));
  });

export const sbRenameGroupChannel = (channelUrl: string, name: string): Promise<GroupChannel> =>
  new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel: SendBird.GroupChannel) => {
        const sb = SendBird.getInstance();
        const params = new sb.GroupChannelParams();
        params.name = name;

        channel.updateChannel(params, (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      })
      .catch((error: SendBird.SendBirdError) => reject(error));
  });

export const sbUnArchiveGroupChannel = (channelUrl: string, id: string): Promise<GroupChannel> =>
  new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel: SendBird.GroupChannel) => {
        const sb = SendBird.getInstance();
        const isDataJson = isJson(channel.data);
        const data = isDataJson ? JSON.parse(channel.data) : null;
        const archivedBy = (data?.archivedBy || [])?.filter((m: string) => m !== id);
        const params = new sb.GroupChannelParams();
        params.data = data ? JSON.stringify({ ...data, archivedBy }) : '{}';
        channel.updateChannel(params, (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      })
      .catch((error: SendBird.SendBirdError) => reject(error));
  });

export const sbUnmuteGroupChannel = (channelUrl: string, id: string): Promise<GroupChannel> =>
  new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel: SendBird.GroupChannel) => {
        const sb = SendBird.getInstance();
        const isDataJson = isJson(channel.data);
        const data = isDataJson ? JSON.parse(channel.data) : null;
        const mutedBy = (data?.mutedBy || [])?.filter((m: string) => m !== id);
        const params = new sb.GroupChannelParams();
        params.data = data ? JSON.stringify({ ...data, mutedBy }) : '{}';
        channel.updateChannel(params, (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      })
      .catch((error: SendBird.SendBirdError) => reject(error));
  });

export const sbCreateGroupChannel = (
  inviteUserIdList: string[],
  operators?: string[],
  clientId?: number,
  name?: string,
  direct?: boolean,
  team?: ITeam,
): Promise<GroupChannel> =>
  new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    const params = new sb.GroupChannelParams();
    params.addUserIds(inviteUserIdList);
    if (operators) {
      params.operatorUserIds = operators;
    }
    if (name) {
      params.name = name;
    }
    const data = {
      clientId: clientId || null,
      direct,
      team: team || undefined,
    };
    params.data = JSON.stringify(data);
    sb.GroupChannel.createChannel(params, (channel, error) => {
      if (error) {
        reject(error);
      } else {
        if (channel) {
          channel.createMetaData({
            clientId: clientId ? String(clientId) : undefined,
            teamId: team ? String(team.id) : undefined,
            env: getEnv(),
          }); // for faster filtering/searching on BE
        }
        resolve(channel);
      }
    });
  });

export const sbUpdateOperators = (
  userIdList: string[],
  channelUrl: string,
): Promise<GroupChannel> => {
  const sb = SendBird.getInstance();
  const params = new sb.GroupChannelParams();
  params.operatorUserIds = userIdList;
  return new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then(channel => {
        channel.updateChannel(params, (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const sbMarkAsReadGroupChannel = (channelUrl: string): Promise<void> =>
  new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel: SendBird.GroupChannel) => {
        channel.markAsRead((response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      })
      .catch((error: SendBird.SendBirdError) => reject(error));
  });

export const sbGetOnlineMemberList = (channelUrl: string): Promise<string[]> =>
  new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then(channel => {
        channel.refresh((c, error) => {
          if (error) {
            reject(error);
          } else {
            const list = c.members.reduce((array: string[], member) => {
              if (member.connectionStatus === ConnectionStatus.ONLINE) {
                return [...array, member.userId];
              }
              return array;
            }, []);
            resolve(list);
          }
        });
      })
      .catch((error: SendBird.SendBirdError) => reject(error));
  });

export const sbUpdateTextMessage = (
  channelUrl: string,
  messageId: number,
  message: string,
  prevMessage: UserMessage,
): Promise<UserMessage> =>
  new Promise((resolve, reject) => {
    const isDataJson = isJson(prevMessage.data);
    const dataObject = isDataJson ? JSON.parse(prevMessage.data) : null;
    const data = dataObject
      ? JSON.stringify({
          ...dataObject,
          editHistory: {
            ...dataObject.editHistory,
            [new Date().getTime()]: prevMessage.message,
          },
        })
      : JSON.stringify({
          editHistory: {
            [new Date().getTime()]: prevMessage.message,
          },
        });
    sbGetGroupChannel(channelUrl)
      .then(channel => {
        channel.updateUserMessage(messageId, message, data, '', (res, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(res);
          }
        });
      })
      .catch(error => {
        reject(error);
      });
  });

export const sbSendDocumentMessage = (
  channelUrl: string,
  file: File,
  type: MessageTypes,
  cb?: (reqId: string) => void,
): Promise<FileMessage> =>
  new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then(channel => {
        const sb = SendBird.getInstance();
        const params = new sb.FileMessageParams();

        params.file = file;
        params.customType = type;

        channel.sendFileMessages([params], {
          progress: (_e: ProgressEvent, messageRequestId: string) => {
            cb?.(messageRequestId);
          },
          sent: (message: FileMessage, err: SendBirdError) => {
            if (err) {
              reject(err);
            } else {
              resolve(message as FileMessage);
            }
          },
          complete: () => {},
        });
      })
      .catch(error => {
        reject(error);
      });
  });

export const sbCancelDocumentMessage = (channelUrl: string, reqId: string): Promise<boolean> =>
  new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then(channel => {
        channel.cancelUploadingFileMessage(reqId, (res, err) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      })
      .catch(error => {
        reject(error);
      });
  });

export default {};
